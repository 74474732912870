import * as React from "react"
import { graphql, Link } from "gatsby"
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { linkResolver } from '../../../../utils/link_resolver'
import Seo from '../../../../components/seo'
import Footer from '../../../../components/footer'
import Header from '../../../../components/header'
import Helper from '../../../../components/helper'
import HelperSizeDogCollars from '../../../../components/helper_size_dog_collars'
import CartContext from '../../../../components/cart_context';
import ProductQuantity from '../../../../components/product_quantity';
import { useState, useContext, useEffect } from 'react'
import ProductSafety from "../../../../components/product_safety"
import ProductHeader from "../../../../components/product_header"
import ProductCustomHeader from "../../../../components/product_custom_header"
import Breadcrumb from "../../../../components/breadcrumb"
import Validate from '../../../../components/validation.js';
import FieldText from '../../../../components/field_text.js';
import FieldErrorMessage from '../../../../components/field_error_message';

const DogsCollarsQuickRelease = ({data}) => {
  
  //console.log('data: ', data)
  const entry = data.prismicDogsCollarsQuickRelease

  const {getProductById} = useContext(CartContext);
  //console.log('getProductById: ', getProductById)

  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [selectedSize, setSelectedSize] = useState(data.shopifyProduct.options[0].values[0])
  
  let custom_strap_colour = getStockLevels(entry.data.custom_strap_colour, 'custom_strap_colour');
  const [selectedStrapColour, setSelectedStrapColour] = useState(custom_strap_colour[0].name)
  const [selectedStrapColourImage, setSelectedStrapColourImage] = useState(custom_strap_colour[0])

  let custom_keeper_colour = getStockLevels(entry.data.custom_keeper_colour, 'custom_keeper_colour');
  const [selectedKeeperColour, setSelectedKeeperColour] = useState(custom_keeper_colour[0].name)
  const [selectedKeeperColourImage, setSelectedKeeperColourImage] = useState(custom_keeper_colour[0])
  
  let custom_hardware_colour = getStockLevels(entry.data.custom_hardware_colour, 'custom_hardware_colour');
  const [selectedHardwareColour, setSelectedHardwareColour] = useState(custom_hardware_colour[0].name)
  const [selectedHardwareColourImage, setSelectedHardwareColourImage] = useState(custom_hardware_colour[0])

  let custom_hardware_plastic_colour = getStockLevels(entry.data.custom_hardware_plastic_colour, 'custom_hardware_plastic_colour');
  const [selectedHardwarePlasticColour, setSelectedHardwarePlasticColour] = useState(custom_hardware_plastic_colour[0].name)
  const [selectedHardwarePlasticColourImage, setSelectedHardwarePlasticColourImage] = useState(custom_hardware_plastic_colour[0])

  const [selectedHardwareOption, setSelectedHardwareOption] = useState('Metal Buckle')

  let custom_name_plate_font = entry.data.custom_name_plate_font;
  const [selectedNamePlateFont, setSelectedNamePlateFont] = useState(custom_name_plate_font[0].name)

  let custom_name_plate_font_colour = entry.data.custom_name_plate_font_colour;
  const [selectedNamePlateFontColour, setSelectedNamePlateFontColour] = useState(custom_name_plate_font_colour[0].name)

  const [selectedPersonalisationOption, setSelectedPersonalisationOption] = useState(data.shopifyProduct.options[2].values[0])
  const [selectedNamePlateGraphic, setSelectedNamePlateGraphic] = useState(entry.data.custom_name_plate_graphic[0].name)
  
  const [formValues, setFormValues] = useState({
    formErrors: '',
    formIsValid: true,
    formControls: {
      name_plate_text_1: {
        type: 'text',
        value: '',
        placeholder: '',
        valid: false,
        touched: false,
        validationRules: {
            isRequired:  true,
            maxLength: '12'
        },
        errorMessages: ''
      },
      name_plate_text_2: {
        type: 'text',
        value: '',
        placeholder: '',
        valid: true,
        touched: false,
        validationRules: {
            maxLength: '12'
        },
        errorMessages: ''
      }
    }
  });

  const [customSelections, setCustomSelections] = useState([
    {
      key: "Strap Colour",
      value: selectedStrapColour
    },
    {
      key: "Keeper Colour",
      value: selectedKeeperColour
    },
    {
      key: "Hardware Option",
      value: selectedHardwareOption
    },
    {
      key: "Hardware Colour",
      value: selectedHardwareColour
    },
    {
      key: "Personalisation",
      value: selectedPersonalisationOption
    },
    {
      key: "Personalisation Text 1",
      value: ''
    },
    {
      key: "Personalisation Text 2",
      value: ''
    },
    {
      key: "Personalisation Font",
      value: ''
    },
    {
      key: "Personalisation Font Colour",
      value: ''
    },
    {
      key: "Personalisation Graphic",
      value: ''
    },
  ])

  useEffect(() => {
    getProductById(data.shopifyProduct.shopifyId).then(result => {
      setProduct(result);
      if (result) {
        setSelectedVariant(
          result.variants.find(({ id }) => id === variantId) || result.variants[0]
        );
      }
    });
    //console.log('customSelections: ', customSelections);
    //console.log('selectedVariantChange: ', selectedVariant);
  }, [
    getProductById,
    setProduct,
    data.shopifyProduct.shopifyId,
    variantId,
    selectedStrapColour,
    selectedStrapColourImage,
    selectedKeeperColour,
    selectedKeeperColourImage,
    selectedHardwareOption,
    selectedHardwareColour,
    selectedHardwareColourImage,
    selectedHardwarePlasticColour,
    selectedHardwarePlasticColourImage,
    selectedPersonalisationOption,
    selectedNamePlateFont,
    selectedNamePlateFontColour,
    selectedNamePlateGraphic,
    customSelections
  ]);

  function getStockLevels(data, component) {
    const newStock = data.filter(colour => {
      return !entry.data.out_of_stock.some(stock => {
        if (stock.size === selectedSize && stock.component === component) {
          return stock.name === colour.name;
        }
        return false;
      });
    });
    return newStock;
  }

  function newCustomSelections(component, name, prevCustomSelections) {
    const newCustomSelections = prevCustomSelections.map(customSelection => {
      if (customSelection.key === component) {
        return {...customSelection, value: name};
      }
      return customSelection;
    });
    return newCustomSelections;
  }

  const handleVariantSizeChange = e => {
    custom_strap_colour = getStockLevels(entry.data.custom_strap_colour, 'custom_strap_colour');
    setSelectedStrapColour(custom_strap_colour[0].name)
    setSelectedStrapColourImage(custom_strap_colour[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Colour', custom_strap_colour[0].name, prevCustomSelections));

    custom_keeper_colour = getStockLevels(entry.data.custom_keeper_colour, 'custom_keeper_colour');
    setSelectedKeeperColour(custom_keeper_colour[0].name)
    setSelectedKeeperColourImage(custom_keeper_colour[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Keeper Colour', custom_keeper_colour[0].name, prevCustomSelections));

    setSelectedHardwareOption('Metal Buckle')
    setCustomSelections(prevCustomSelections => newCustomSelections('Hardware Option', 'Metal Buckle', prevCustomSelections));
    
    custom_hardware_colour = getStockLevels(entry.data.custom_hardware_colour, 'custom_hardware_colour');
    setSelectedHardwareColour(custom_hardware_colour[0].name)
    setSelectedHardwareColourImage(custom_hardware_colour[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Hardware Colour', custom_hardware_colour[0].name, prevCustomSelections));
    //console.log('custom_hardware_colour: ', custom_hardware_colour);
    //console.log('custom_hardware_colour[0].name: ', custom_hardware_colour[0].name);
  
    setSelectedSize(e.target.id);
    let selectedVariantChange = `${e.target.id} / ${custom_hardware_colour[0].name} / ${selectedPersonalisationOption}`;

    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    );
    setVariantId(newVariant.shopifyId);
    //console.log('selectedVariantChange: ', selectedVariantChange);
    //console.log('newVariant: ', newVariant);
  };

  const handleStrapColourChange = e => {
    setSelectedStrapColour(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Strap Colour') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
    const newSelectedStrapColourImage = entry.data.custom_strap_colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedStrapColourImage(newSelectedStrapColourImage)
  };

  const handleKeeperColourChange = e => {
    setSelectedKeeperColour(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Keeper Colour') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
    const newSelectedKeeperColourImage = entry.data.custom_keeper_colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedKeeperColourImage(newSelectedKeeperColourImage)
  };

  const handleHardwareOptionChange = e => {
    setSelectedHardwareOption(e.target.value);
    setCustomSelections(prevCustomSelections => newCustomSelections('Hardware Option', e.target.value, prevCustomSelections));
    //console.log('setSelectedHardwareOption: ', e.target.value);

    if (e.target.value === 'Metal Buckle') {
      custom_hardware_colour = getStockLevels(entry.data.custom_hardware_colour, 'custom_hardware_colour');
      
      setSelectedHardwareColour(custom_hardware_colour[0].name)
      setSelectedHardwareColourImage(custom_hardware_colour[0])
      setCustomSelections(prevCustomSelections => newCustomSelections('Hardware Colour', custom_hardware_colour[0].name, prevCustomSelections));
      
      //console.log('custom_hardware_colour: ', custom_hardware_colour);
      //console.log('custom_hardware_colour[0].name: ', custom_hardware_colour[0].name);

      let selectedVariantChange = `${selectedSize} / ${custom_hardware_colour[0].name} / ${selectedPersonalisationOption}`
      let newVariant = data.shopifyProduct?.variants.find(
        variant => variant.title === selectedVariantChange
      )
      setVariantId(newVariant.shopifyId);
      //console.log('selectedVariantChange: ', selectedVariantChange);
      //console.log('newVariant: ', newVariant);

    } else { 
      custom_hardware_plastic_colour = getStockLevels(entry.data.custom_hardware_plastic_colour, 'custom_hardware_plastic_colour');
      
      setSelectedHardwarePlasticColour(custom_hardware_plastic_colour[0].name)
      setSelectedHardwarePlasticColourImage(custom_hardware_plastic_colour[0])
      setCustomSelections(prevCustomSelections => newCustomSelections('Hardware Colour', custom_hardware_plastic_colour[0].name, prevCustomSelections));
    
      //console.log('custom_hardware_plastic_colour: ', custom_hardware_plastic_colour);
      //console.log('custom_hardware_plastic_colour[0].name: ', custom_hardware_plastic_colour[0].name);

      let selectedVariantChange = `${selectedSize} / Plastic & Stainless Steel / ${selectedPersonalisationOption}`
      let newVariant = data.shopifyProduct?.variants.find(
        variant => variant.title === selectedVariantChange
      )
      setVariantId(newVariant.shopifyId);
      //console.log('selectedVariantChange: ', selectedVariantChange);
      //console.log('newVariant: ', newVariant);
    }
  };

  const handleHardwarePlasticColourChange = e => {
    setSelectedHardwarePlasticColour(e.target.value);
    //console.log('setSelectedHardwarePlasticColour: ', e.target.value);

    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Hardware Colour') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)

    const newSelectedHardwarePlasticColourImage = entry.data.custom_hardware_plastic_colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedHardwarePlasticColourImage(newSelectedHardwarePlasticColourImage)
  };

  const handleHardwareColourChange = e => {
    setSelectedHardwareColour(e.target.value);
    let selectedVariantChange = `${selectedSize} / ${e.target.value} / ${selectedPersonalisationOption}`
    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId);
    //console.log('selectedVariantChange: ', selectedVariantChange);
    //console.log('newVariant: ', newVariant);
    //console.log('setSelectedHardwareColour: ', e.target.value);

    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Hardware Colour') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
    const newSelectedHardwareColourImage = entry.data.custom_hardware_colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedHardwareColourImage(newSelectedHardwareColourImage)
    //console.log('newCustomSelections: ', newCustomSelections);
  };

  const handleVariantPersonalisationOptionChange = e => {
    setSelectedPersonalisationOption(e.target.id);
    
    let selectedVariantChange = ``;
    if (selectedHardwareOption === 'Metal Buckle') {
      selectedVariantChange = `${selectedSize} / ${selectedHardwareColour} / ${e.target.id}`
    } else {
      selectedVariantChange = `${selectedSize} / Plastic & Stainless Steel / ${e.target.id}`
    }

    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId);
    //console.log('newVariant: ', newVariant);

    if (e.target.id === 'Yes (add $10)') {
      const newCustomSelections = customSelections.map(customSelection => {
        if (customSelection.key === 'Personalisation') {
          return {...customSelection, value: e.target.id};
        }
        if (customSelection.key === 'Personalisation Text 1') {
          return {...customSelection, value: formValues.formControls.name_plate_text_1.value};
        }
        if (customSelection.key === 'Personalisation Text 2') {
          return {...customSelection, value: formValues.formControls.name_plate_text_2.value};
        }
        if (customSelection.key === 'Personalisation Font') {
          return {...customSelection, value: selectedNamePlateFont};
        }
        if (customSelection.key === 'Personalisation Font Colour') {
          return {...customSelection, value: selectedNamePlateFontColour};
        }
        if (customSelection.key === 'Personalisation Graphic') {
          return {...customSelection, value: selectedNamePlateGraphic};
        }
        return customSelection;
      });
      setCustomSelections(newCustomSelections)
      
      let isNamePlateFormValid = formValues.formControls.name_plate_text_1.value !== '';

      // enable required field
      setFormValues({
        ...formValues,
        formIsValid: isNamePlateFormValid,
      });
    }

    if (e.target.id === 'No') {
      const newCustomSelections = customSelections.map(customSelection => {
        if (customSelection.key === 'Personalisation') {
          return {...customSelection, value: e.target.id};
        }
        if (customSelection.key === 'Personalisation Text 1') {
          return {...customSelection, value: ''};
        }
        if (customSelection.key === 'Personalisation Text 2') {
          return {...customSelection, value: ''};
        }
        if (customSelection.key === 'Personalisation Font') {
          return {...customSelection, value: ''};
        }
        if (customSelection.key === 'Personalisation Font Colour') {
          return {...customSelection, value: ''};
        }
        if (customSelection.key === 'Personalisation Graphic') {
          return {...customSelection, value: ''};
        }
        return customSelection;
      });
      setCustomSelections(newCustomSelections)
      
      // disabled required field
      setFormValues({
        ...formValues,
        formIsValid: true,
      });
    }
    
  };

  const handleNamePlateFontChange = e => {
    setSelectedNamePlateFont(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Personalisation Font') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
  };

  const handleNamePlateFontColourChange = e => {
    setSelectedNamePlateFontColour(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Personalisation Font Colour') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
  };

  const changeFormHandler = e => {
    const name = e.target.name;
    const value = e.target.value;

    const updatedControls = {
      ...formValues.formControls
    };
    const updatedFormElement = {
      ...updatedControls[name]
    };
    updatedFormElement.value = value;
    updatedFormElement.touched = true;

    let validateFormElement = [];
    validateFormElement = Validate(value, updatedFormElement.validationRules);

    updatedFormElement.valid = validateFormElement.isValid;
    updatedFormElement.errorMessages = validateFormElement.errorMessages;

    updatedControls[name] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }

    setFormValues({
      ...formValues,
      formControls: updatedControls,
      formIsValid: formIsValid,
    });

    const newCustomSelections = customSelections.map(customSelection => {
      if (name === 'name_plate_text_1' && customSelection.key === 'Personalisation Text 1') {
        return {...customSelection, value: value};
      }
      if (name === 'name_plate_text_2' && customSelection.key === 'Personalisation Text 2') {
        return {...customSelection, value: value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
  }

  const handleNamePlateGraphicChange = e => {
    setSelectedNamePlateGraphic(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Personalisation Graphic') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
  };

  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <Breadcrumb 
        category="Dogs" 
        category_url="dogs"
        subcategory="Collars" 
        subcategory_url="collars"
        product={entry.data.heading} 
      />
      <ProductHeader data={entry.data} />
      <ProductCustomHeader props="Customise Yours" />

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/size_duo_white.png" className="w-24 h-24" alt="Strap" /> 
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose size</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <HelperSizeDogCollars name="Size guide" data={data} />
                </div>
              </div>
              {!!selectedVariant && 
                <div>
                  <div className="sr-only">Choose size</div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                    {data.shopifyProduct.options[0].values.map((name,i) => (
                      <label htmlFor={name} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                        <input 
                          id={name}
                          defaultChecked={name === selectedSize}
                          type="radio"
                          value={name}
                          onChange={handleVariantSizeChange}
                          name="size"
                          className={name === selectedSize ? 'w-5 h-5 font-poppins text-lg bg-orange text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                        />
                        {name}
                      </label>
                    ))}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/strap_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose strap colour</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Strapping" data={entry.data.strapping} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose strap colour</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                      {custom_strap_colour.map((custom,i) => (
                        <label htmlFor={`s_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`s_${custom.name}`}
                            checked={custom.name === selectedStrapColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleStrapColourChange}
                            name="strap_colour"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedStrapColour ? 'w-5 h-5 text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full h-24 block" image={selectedStrapColourImage.image.gatsbyImageData} alt={selectedStrapColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/strap_white.png" className="w-28 h-28" alt="Strap" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose strap keeper colour</h2>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose strap keeper colour</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                      {custom_keeper_colour.map((custom,i) => (
                        <label htmlFor={`custom_keeper_colour_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`custom_keeper_colour_${custom.name}`}
                            checked={custom.name === selectedKeeperColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleKeeperColourChange}
                            name="keeper_colour"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedKeeperColour ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full h-24 block" image={selectedKeeperColourImage.image.gatsbyImageData} alt={selectedKeeperColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/hardware_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-3">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose hardware</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Hardware" data={entry.data.hardware} />
                </div>
              </div>
              <div className="font-poppins max-w-none pb-12 prose prose-h2:text-orange prose-h2:mb-6 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium leading-snug">
                <PrismicRichText linkResolver={linkResolver} field={entry.data.hardware_text.richText} />
              </div>

              <div className="w-full pb-12">
                {!!selectedVariant && 
                  <div className="flex justify-between gap-6">
                    <div className="sr-only">Hardware option</div>
                    <div className="w-9/12">
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                        
                        <label htmlFor="Metal Buckle" className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id="Metal Buckle"
                            checked={"Metal Buckle" === selectedHardwareOption}
                            type="radio"
                            value="Metal Buckle"
                            onChange={handleHardwareOptionChange}
                            name="hardware_option"
                            className={"Metal Buckle" === selectedHardwareOption ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          Metal Buckle
                        </label>
                        <label htmlFor="Plastic Buckle" className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id="Plastic Buckle"
                            checked={"Plastic Buckle" === selectedHardwareOption}
                            type="radio"
                            value="Plastic Buckle"
                            onChange={handleHardwareOptionChange}
                            name="hardware_option"
                            className={"Plastic Buckle" === selectedHardwareOption ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          Plastic Buckle
                        </label>
                        
                      </div>
                    </div>
                    <div className="w-3/12">
                      &nbsp;
                    </div>
                  </div>
                }
              </div>

              {!!selectedVariant && 'Metal Buckle' === selectedHardwareOption ?
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose hardware</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                      {custom_hardware_colour.map((custom,i) => (
                        <label htmlFor={`h_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`h_${custom.name}`}
                            checked={custom.name === selectedHardwareColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleHardwareColourChange}
                            name="hardware_colour"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedHardwareColour ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full block" image={selectedHardwareColourImage.image.gatsbyImageData} alt={selectedHardwareColourImage.image.alt ?? ''} />
                  </div>
                </div>
                : 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose plastic hardware</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                      {custom_hardware_plastic_colour.map((custom,i) => (
                        <label htmlFor={`hp_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`hp_${custom.name}`}
                            checked={custom.name === selectedHardwarePlasticColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleHardwarePlasticColourChange}
                            name="hardware_plastic_colour"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedHardwarePlasticColour ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full block" image={selectedHardwarePlasticColourImage.image.gatsbyImageData} alt={selectedHardwarePlasticColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-16 md:pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/personalisation_white.png" className="w-28 h-28" alt="Personalise Personalisation" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-3">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Personalise</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Personalisation" data={entry.data.name_plates} />
                </div>
              </div>
              <div className="font-poppins max-w-none pb-12 prose prose-h2:text-orange prose-h2:mb-6 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium leading-snug">
                <PrismicRichText linkResolver={linkResolver} field={entry.data.personalisation.richText} />
              </div>
              <div className={selectedPersonalisationOption === 'No' ? 'w-full' : 'w-full pb-12'}>
                {!!selectedVariant && 
                  <div className="flex justify-between gap-6">
                    <div className="sr-only">Personalisation</div>
                    <div className="w-9/12">
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                        {data.shopifyProduct.options[2].values.map((name,i) => (
                          <label htmlFor={name} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                            <input
                              id={name}
                              defaultChecked={name === selectedPersonalisationOption}
                              type="radio"
                              value={name}
                              onChange={handleVariantPersonalisationOptionChange}
                              name="personalisation"
                              className={name === selectedPersonalisationOption ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                            />
                            {name}
                          </label>
                        ))}
                      </div>
                    </div>
                    <div className="w-3/12">
                      &nbsp;
                    </div>
                  </div>
                }
              </div>

              <div className={selectedPersonalisationOption === 'No' ? 'hidden' : 'w-full pt-6 md:pt-0'}>
              {!!selectedVariant && 
                <div className="flex-none md:flex justify-between gap-6">
                  <div className="sr-only">Personalisation</div>
                  <div className="w-full md:w-9/12">
                    
                    <div>
                      <div className="flex items-center justify-between pb-6">
                        <h2 className="font-poppins text-2xl font-semibold text-diamond">Choose your scrag tag colour</h2>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                        {custom_name_plate_font_colour.map((custom,i) => (
                          <label htmlFor={`name_plate_font_colour_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                            <input
                              id={`name_plate_font_colour_${custom.name}`}
                              checked={custom.name === selectedNamePlateFontColour}
                              type="radio"
                              value={custom.name}
                              onChange={handleNamePlateFontColourChange}
                              name="custom_name_plate_font_colour"
                              style={{backgroundColor: `${custom.colour}`}}
                              className={custom.name === selectedNamePlateFontColour ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                            />
                            {custom.name}
                          </label>
                        ))}
                      </div>
                    </div>
                    
                    <div className="pt-12">
                      <div className="flex-none md:flex gap-6">
                        <div className="w-full md:w-6/12">
                          <label htmlFor="name_plate_text_1" className="font-poppins text-2xl font-semibold text-diamond pb-3 block">Add your text <small className="font-italic text-sm">(field 1)</small></label>
                          <FieldText
                            id="name_plate_text_1"
                            name="name_plate_text_1"
                            type={formValues.formControls.name_plate_text_1.type}
                            placeholder={formValues.formControls.name_plate_text_1.placeholder}
                            value={formValues.formControls.name_plate_text_1.value}
                            onChange={changeFormHandler}
                            className={formValues.formControls.name_plate_text_1.touched && !formValues.formControls.name_plate_text_1.valid ? 'w-full px-2 py-1 border-2 border-orange mb-1 focus:border-orange focus:outline-none focus:ring-0' : 'font-poppins font-medium text-diamond-80 w-full px-2 py-1 border-2 border-diamond-50 mb-4 focus:border-diamond-80 focus:outline-none focus:ring-0'}
                          />
                          <FieldErrorMessage
                            className={formValues.formControls.name_plate_text_1.touched && !formValues.formControls.name_plate_text_1.valid ? 'block text-xs text-orange mb-4' : 'hidden'}
                            errorMessages={formValues.formControls.name_plate_text_1.errorMessages}
                          />
                        </div>
                        <div className="w-full md:w-6/12">
                          <label htmlFor="name_plate_text_2" className="font-poppins text-2xl font-semibold text-diamond pb-3 block">Add your text <small className="font-italic text-sm">(field 2)</small></label>
                          <FieldText
                            id="name_plate_text_2"
                            name="name_plate_text_2"
                            type={formValues.formControls.name_plate_text_2.type}
                            placeholder={formValues.formControls.name_plate_text_2.placeholder}
                            value={formValues.formControls.name_plate_text_2.value}
                            onChange={changeFormHandler}
                            className={formValues.formControls.name_plate_text_2.touched && !formValues.formControls.name_plate_text_2.valid ? 'w-full px-2 py-1 border-2 border-orange mb-1 focus:border-orange focus:outline-none focus:ring-0' : 'font-poppins font-medium text-diamond-80 w-full px-2 py-1 border-2 border-diamond-50 mb-4 focus:border-diamond-80 focus:outline-none focus:ring-0'}
                          />
                          <FieldErrorMessage
                            className={formValues.formControls.name_plate_text_2.touched && !formValues.formControls.name_plate_text_2.valid ? 'block text-xs text-orange mb-4' : 'hidden'}
                            errorMessages={formValues.formControls.name_plate_text_2.errorMessages}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="pt-6">
                      <div className="flex items-center justify-between pb-6">
                        <h2 className="font-poppins text-2xl font-semibold text-diamond">Choose your font <small className="text-sm"> (all phone numbers are in Calibri for easy readability)</small></h2>
                      </div>
                      <div className="grid grid-cols-3 md:grid-cols-4 gap-3 text-left">
                        {entry.data.custom_name_plate_font.map((custom, i) => (
                          <label htmlFor={`name_plate_font_${custom.name}`} key={i} className={custom.name === selectedNamePlateFont ? 'flex items-center rounded-xl border-2 border-diamond cursor-pointer' : 'flex items-center border-2 border-transparent cursor-pointer'}>
                            <input
                              id={`name_plate_font_${custom.name}`}
                              defaultChecked={custom.name === selectedNamePlateFont}
                              type="radio"
                              value={custom.name}
                              onChange={handleNamePlateFontChange}
                              name="custom_name_plate_font"
                              className="hidden"
                            />
                            <GatsbyImage className="object-fit w-full block" image={custom.image.gatsbyImageData} alt={custom.image.alt ?? ''} />
                          </label>
                        ))}
                      </div>
                    </div>

                    <div className="pt-12">
                      <div className="flex items-center justify-between">
                        <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:text-2xl prose-h2:font-semibold prose-h2:text-diamond prose-h2:mb-3 prose-p:font-medium leading-snug mb-6">
                          <h2>Choose your graphic</h2>
                          <PrismicRichText linkResolver={linkResolver} field={entry.data.custom_name_plate_graphic_text.richText} />
                        </div>
                      </div>
                      <div className="grid grid-cols-3 md:grid-cols-6 gap-3 text-left ">
                        {entry.data.custom_name_plate_graphic.map((custom, i) => (
                          <label htmlFor={`name_plate_font_${custom.name}`} key={i} className={custom.name === selectedNamePlateGraphic ? 'flex items-center px-6 py-3 rounded-xl border-2 border-diamond cursor-pointer' : 'flex items-center px-6 py-3 border-2 border-transparent cursor-pointer'}>
                            <input
                              id={`name_plate_font_${custom.name}`}
                              defaultChecked={custom.name === selectedNamePlateGraphic}
                              type="radio"
                              value={custom.name}
                              onChange={handleNamePlateGraphicChange}
                              name="name_plate_font"
                              className="hidden"
                            />
                            <GatsbyImage className="object-contain block" image={custom.image.gatsbyImageData} alt={custom.image.alt ?? ''} />
                          </label>
                        ))}
                      </div>
                    </div>

                  </div>
                  <div className="w-full md:w-3/12">
                    &nbsp;
                  </div>
                </div>
              }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/cart_duo_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">

              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Add to cart</h2>
              </div>

              <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:font-semibold prose-h2:text-diamond prose-h2:mb-3 prose-p:font-medium leading-snug mb-12">
                <h2>Please check your selection</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.info.richText} />
              </div>

              {selectedVariant && (
                <div className="w-full pb-12">
                  <div className="font-poppins max-w-none font-semibold text-diamond text-4xl xl:text-6xl">
                    {selectedVariant.compareAtPriceV2 && selectedVariant.priceV2.amount < selectedVariant.compareAtPriceV2.amount ? (
                      <div className="flex flex-col lg:flex-row gap-0 lg:gap-6">
                        <div className="line-through">{currency.format(selectedVariant.compareAtPriceV2.amount)} NZD</div>
                        <div className="text-orange">{currency.format(selectedVariant.priceV2.amount)} NZD</div>
                      </div>
                    ) : (
                      <>
                        {currency.format(selectedVariant.priceV2.amount)} NZD
                      </>
                    )}
                  </div>
                </div>
              )}
              
              {!!selectedVariant && (
              <div className="w-full">
                <ProductQuantity formValues={formValues} variantId={selectedVariant.id} customSelections={customSelections} />
              </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ProductSafety data={entry.data} />
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicSizeGuideDogCollars {
    data {
      heading
      text {
        richText
      }
      text1 {
        richText
      }
      table_1 {
        heading1
        text1
      }
      image {
        alt
        gatsbyImageData
      }
      table_2 {
        heading1
        text1
      }
    }
  }
  prismicDogsCollarsQuickRelease {
    data {
      out_of_stock {
        size
        component
        name
      }
      page_title
      meta_description
      heading
      tag
      description {
        richText
      }
      images {
        image {
          alt
          gatsbyImageData
        }
      }
      safety_and_care_image {
        alt
        gatsbyImageData
      }
      safety_and_care {
        richText
      }
      info {
        richText
      }
      strapping {
        richText
      }
      hardware {
        richText
      }
      embellishments {
        richText
      }
      custom_strap_colour {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      custom_keeper_colour {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      hardware_text {
        richText
      }
      custom_hardware_colour {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      custom_hardware_plastic_colour {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      name_plates {
        richText
      }
      personalisation {
        richText
      }
      custom_name_plate_font {
        name
        image {
          alt
          gatsbyImageData
        }
      }
      custom_name_plate_font_colour {
        name
        colour
      }
      custom_name_plate_graphic_text {
        richText
      }
      custom_name_plate_graphic {
        name
        image {
          alt
          gatsbyImageData
        }
      }
    }
  }
  shopifyProduct(handle: {eq: "dogs-collars-quick-release"}) {
    id
    priceRangeV2 {
      maxVariantPrice {
        amount
      }
    }
    shopifyId
    storefrontId
    status
    variants {
      id
      price
      shopifyId
      storefrontId
      title
      availableForSale
    }
    options {
      id
      name
      values
    }
  }
}
`

export default DogsCollarsQuickRelease